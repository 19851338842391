import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import error404 from "../assets/images/error404.png";
import styles from "../components/Error404.module.scss";

function Error404() {
    return (
        <>
            <Header />
            <main className={styles.error}>
                <div className={styles.error404}>
                    <img src={error404} alt="Icon error 404" />
                </div>
                <p className={styles.subtitleerror}>Oops! The page you are requesting does not exist.</p>
                <div className={styles.link}>
                    <Link to="/" className="btn">Return to home page</Link>
                </div>
            </main>
            <Footer />
        </>
    )
}
export default Error404;