import React from "react";
import ia3 from "../assets/images/ia 3.jpg";
import styles from "./BannerAboutUs.module.scss";
import TypeWriter from "../components/TypeWriter";

function BannerAboutUs() {
    return (
        <section className={styles.banneraboutus}>
            <img src={ia3} alt="Robot IA médical 2" className={styles.img3} />
            <h1><TypeWriter text="Learn more about us" /></h1>
        </section>
    )
}

export default BannerAboutUs;