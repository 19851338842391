import React from "react";
import ia1 from "../assets/images/ia1.jpg";
import styles from "./BannerHome.module.scss";
import TypeWriter from "../components/TypeWriter";

function BannerHome() {
    return (
        <section className={styles.bannerhome}>
            <img src={ia1} alt="Robot IA médical 1" className={styles.img1} />
            <h1><TypeWriter text="Artificial Intelligence for Healthcare" /></h1>
        </section>
    )
}

export default BannerHome;