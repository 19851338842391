import React from "react";
import styles from "./AboutFdevelopment.module.scss";
import imgfab from "../assets/images/fab.jpg";
import certif1 from "../assets/images/certif1.png";
import certif2 from "../assets/images/certif2.png";
import certif3 from "../assets/images/certif3.png";
import comp1 from "../assets/images/comp1.png";
import comp2 from "../assets/images/comp2.png";
import comp3 from "../assets/images/comp3.png";
import comp4 from "../assets/images/comp4.png";

function AboutFdevelopment() {
    return (
        <section className={styles.about}>
            <div className={styles.aboutFdev}>
                <img src={imgfab} className={styles.imgfab} alt="Portrait de FDevelopment" />
                <h3 className={styles.name}>Fabrice</h3>
                <p className={styles.p_aboutus}>. Software Architect - Data Scientist< br />. Methods and pratice in epidemiology< br />. Methods and management of healthcare databases< br />. Director of the company FDevelopment</p>
                <div>
                    <a href="mailto:contact@fdevelopment.eu" className="mail">contact@fdevelopment.eu</a>
                </div>
            </div>
            <section>
                <div className={styles.diploma}>
                    <img src={certif1} className={styles.certification1} alt="Diplômes" />
                    <p className={styles.p_certification}>Certification 70-461< br />Query 2012/2014</p>
                    <img src={certif2} className={styles.certification2} alt="Diplômes" />
                    <p className={styles.p_certification}>Certification Embarcadero< br />Delphi XE</p>
                    <img src={certif3} className={styles.certification3} alt="Diplômes" />
                    <p className={styles.p_certification}>DU Méthode et Gestion< br />DB de la santé</p>
                    <img src={certif3} className={styles.certification3} alt="Diplômes" />
                    <p className={styles.p_certification}>DU Méthode et Pratique en épidémiologie< br />DB de la santé</p>
                </div>
            </section>
            <h2 className={styles.title_skills}>SKILLS</h2>
            <section className={styles.containerskills}>
                <div className={styles.cards}>
                    <img src={comp1} className={styles.icons} alt="Icon de compétence" />
                    <h3 className={styles.title_competence}>Multi Plateform</h3>
                    <p className={styles.p_competence}>We develop all types of applications for Windows, Mac OS , Android and IOS.< br />Transform your heavy client applications under Delphi into multi-OS compatible clienbt/server applications (Windows, Android, IOS, Mac OS, Linux) and even Web thanks to the datanap/RAD server technology.< br />We bring the web broker technique allowing you the trnasfer of cross-platform JSON/REST information.</p>
                </div>
                <div className={styles.cards}>
                    <img src={comp2} className={styles.icons} alt="Icon de compétence" />
                    <h3 className={styles.title_competence}>Web</h3>
                    <p className={styles.p_competence}>We develop all types of websites.< br />Presentation, E-Commerce, etc...</p>
                </div>
                <div className={styles.cards}>
                    <img src={comp3} className={styles.icons} alt="Icon de compétence" />
                    <h3 className={styles.title_competence}>Data Science</h3>
                    <p className={styles.p_competence}>FDevelopment specializes in the development of machine learning and deep learning algorithms.< br />We are currently working on artificial intelligence projects in the medical field, but we can also help you develop predictive algorithms in any other fiels.< br />Please, don't hesitate to contact us.</p>
                </div>
                <div className={styles.cards}>
                    <img src={comp4} className={styles.icons} alt="Icon de compétence" />
                    <h3 className={styles.title_competence}>Council</h3>
                    <p className={styles.p_competence}>We can bring you our expertise in the field of development or optimization of your databases.</p>
                </div>
            </section>
            <div className={styles.container}>
                <h4 className={styles.title_container}>We are developers, development is our voice</h4>
                <p className={styles.p_container}>Computers are an integral part of everyone's daily life, to help us live better, it is important that software is made with competence and logic, our goal is to improve the daily life of everyone.</p>
            </div>
        </section>
    )
}

export default AboutFdevelopment;