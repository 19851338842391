import React from "react";
import Header from "../components/Header";
import BannerServices from "../components/BannerServices";
import ServicesFd from "../components/ServicesFd";
import Footer from "../components/Footer";

function Services() {
    return (
        <>
            <Header />
            <main>
                <BannerServices />
                <ServicesFd />
            </main>
            <Footer />
        </>
    )
}
export default Services;