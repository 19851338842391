import React from "react";
import styles from "./MobileDevelopment.module.scss";
import operatingsysteme from "../assets/images/operating systeme.png";
import cellphone from "../assets/images/cellphone.png";

function MobileDevelopment() {
    return (
        <section>
            <h2 className={styles.title_mobiledevelopment}>MOBILE DEVELOPMENT</h2>
            <p className={styles.p_mobiledevelopment}>We develop your mobile applications IOS and Android, do not hesitate to contact us for a personalized quote whatever the field, there is a solution adapted to your needs.</p>
            <div className="d-flex justify-content-center align-items-center">
                <img src={operatingsysteme} className={styles.opsys} alt="Icon de système d'exploitation" />
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <img src={cellphone} className={styles.phone} alt="Icon de téléphone portable" />
            </div>
            <h4 className={styles.title_app}>We create for you any type of application</h4>
            <p className={styles.p_app}>Specialized in the medical field, we have more than 20 years of experience in the development of applications of all kinds, mobile applications, collection, invoicing, estimates, commercial management, web site, e-commerce site. Do not hesitate to contact us for any request.</p>
            <div className={styles.container}>
                <h4 className={styles.title_container}>We are developers, development is our voice</h4>
                <p className={styles.p_container}>Computers are an integral part of everyone's daily life, to help us live better, it is important that software is made with competence and logic, our goal is to improve the daily life of everyone.</p>
            </div>
        </section>

    )
}

export default MobileDevelopment;