import React from "react";
import logo from "../assets/images/logo.png";
import { NavLink } from "react-router-dom";
import styles from "./Header.module.scss";

function Header() {

    return (
        <header className={`${styles.header} d-flex flex-row align-items-center`}>
            <div className="flex-fill">
                <NavLink to="/">
                    <img src={logo} alt="Logo FDevelopment" className={styles.logoheader} />
                </NavLink>
            </div>
            <ul className="d-flex">
                <li>
                    <NavLink to="/" className={(nav) => (nav.isActive ? "nav-active" : "nav-inactive")}>
                        Home
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/aboutus" className={(nav) => (nav.isActive ? "nav-active" : "nav-inactive")}>
                        About Us
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/services" className={(nav) => (nav.isActive ? "nav-active" : "nav-inactive")}>
                        Services
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/projects" className={(nav) => (nav.isActive ? "nav-active" : "nav-inactive")}>
                        Projects
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/contact" className={(nav) => (nav.isActive ? "nav-active" : "nav-inactive")}>
                        Contact
                    </NavLink>
                </li>
            </ul>
        </header>
    )
}

export default Header;