import React, { useState, useEffect } from "react";

function TypeWriter({ text }) {
    const [currentText, setCurrentText] = useState("");
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index < text.length) {
            const timer = setTimeout(() => {
                setCurrentText(currentText => currentText + text.charAt(index));
                setIndex(index => index + 1);
            }, 80);
            return () => clearTimeout(timer);
        }
    }, [index, text]);

    return <span>{currentText}</span>;
}

export default TypeWriter;