import React from "react";
import ia4 from "../assets/images/ia 4.jpg";
import styles from "./BannerServices.module.scss";
import TypeWriter from "../components/TypeWriter";

function BannerServices() {
    return (
        <section className={styles.bannerservices}>
            <img src={ia4} alt="Robot IA médical 3" className={styles.img4} />
            <h1><TypeWriter text="We can bring you a lot ask yourself the question we have the answer" /></h1>
        </section>
    )
}

export default BannerServices;