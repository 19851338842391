/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import styles from "./ContactFDev.module.scss";

function ContactFDev() {
    return (
        <section className={styles.contactus}>
            <section className={styles.container}>
                <h1 className={styles.title_container}>Contact us</h1>
            </section>
            <div className="d-flex flex-column justify-content-center align-items-center">
                <p className={styles.contact}>We are available 24/7.< br />Do not hesitate to contact us.</p>
                <p className={styles.address}>71-75 Shelton St, West End,< br />London WC2H9JQ< br />United Kingdom</p>
                <p className={styles.phonenumber}>+44 020-397-2233</p>
                <div>
                    <a href="mailto:contact@fdevelopment.eu" className="mail">contact@fdevelopment.eu</a>
                </div>
            </div>
            <center className={styles.map}>
                <div className="mapouter"><div className="gmap_canvas"><iframe width="240" height="352" id="gmap_canvas" src="https://maps.google.com/maps?q=71-75%20Shelton%20St%2C%20West%20End%2C%20%20London%20WC2H%209JQ%2C%20Royaume-Uni&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe><a href="https://www.whatismyip-address.com/nordvpn-coupon/"></a></div><style>.mapouter"position:relative;text-align:right;height:476px;width:474px;".gmap_canvas "overflow:hidden;background:none!important;height:476px;width:474px;"</style></div>
            </center>
        </section>
    )
}

export default ContactFDev;