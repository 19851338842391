import React from "react";
import Header from "../components/Header";
import BannerProjects from "../components/BannerProjects";
import ProjectsFdev from "../components/ProjectsFdev";
import Footer from "../components/Footer";

function Projects() {
    return (
        <>
            <Header />
            <main>
                <BannerProjects />
                <ProjectsFdev />
            </main>
            <Footer />
        </>
    )
}
export default Projects;