import React from "react";
import styles from "./ServicesFd.module.scss";
import datasciences from "../assets/images/datasciences.png";
import training2 from "../assets/images/training2.png";
import advices from "../assets/images/advice.png";
import dev from "../assets/images/dev.png";
import development from "../assets/images/development.png";


function ServicesFd() {
    return (
        <section className={styles.services}>
            <section className={styles.containerservices}>
                <div className={styles.cards}>
                    <img src={datasciences} className={styles.icons} alt="Icon science des données" />
                    <h3 className={styles.title_services}>DATA SCIENCES</h3>
                    <p className={styles.p_services}>Al algorithms tailored to healthcare, but adaptable to other fields.< br />Our unique expertise in machine learning enables us to create customized solutions that meet today's medical challenges, while remaining flexible for other industries.< br />Let's work together for a technology that anticipates, cares and innovates.< br />Let's transform the future.</p>
                </div>
                <div className={styles.cards}>
                    <img src={training2} className={styles.icons} alt="Icon entraînement" />
                    <h3 className={styles.title_services}>TRAINING</h3>
                    <p className={styles.p_services}>We can train your teams in object-oriented programming, whether you are a beginner or an experienced developer, we can perhaps improve your knowledge of Delphi with our Certified Delphi Embarcadero or PHP, but also in SQL TSQL with our Microsoft certification.</p>
                </div>
                <div className={styles.cards}>
                    <img src={advices} className={styles.icons} alt="Icon conseils" />
                    <h3 className={styles.title_services}>ADVICES</h3>
                    <p className={styles.p_services}>We can bring you our help on the realization, the improvement or the optimization of your project, elaboration of your software and database optimization.</p>
                </div>
                <div className={styles.cards}>
                    <img src={dev} className={styles.icons} alt="Icon développement" />
                    <h3 className={styles.title_services}>WIN/MAC DEVELOPMENT</h3>
                    <p className={styles.p_services}>Thanks to our skills and experience in many fields, we are able to manage and develop your project.< br />Do not hesitate to contact us.< br />We probably have a solution for you.</p>
                </div>
                <div className={styles.cards}>
                    <img src={development} className={styles.icons} alt="Icon de développment web" />
                    <h3 className={styles.title_services}>WEB DEVELOPMENT</h3>
                    <p className={styles.p_services}>We realize for you your website.< br />Showcase or E-Commerce, PHP, Bootstrap, Prestashop,our skills are multiple.</p>
                </div>
            </section>
            <div className={styles.container}>
                <h4 className={styles.title_container}>We are developers, development is our voice</h4>
                <p className={styles.p_container}>Computers are an integral part of everyone's daily life, to help us live better, it is important that software is made with competence and logic, our goal is to improve the daily life of everyone.</p>
            </div>
        </section>
    )
}

export default ServicesFd;