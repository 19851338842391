import React from "react";
import Header from "../components/Header";
import Background from "../components/Background";
import ContactFDev from "../components/ContactFDev";
import Footer from "../components/Footer";

function Contact() {
    return (
        <>
            <Header />
            <main>
                <Background />
                <ContactFDev />
            </main>
            <Footer />
        </>
    )
}
export default Contact;