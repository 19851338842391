import React from "react";
import Header from "../components/Header";
import BannerAboutUs from "../components/BannerAboutUs";
import AboutFdevelopment from "../components/AboutFdevelopment";
import Footer from "../components/Footer";

function AboutUs() {
    return (
        <>
            <Header />
            <main>
                <BannerAboutUs />
                <AboutFdevelopment />
            </main>
            <Footer />
        </>
    )
}
export default AboutUs;