import React from "react";
import Header from "../components/Header";
import BannerHome from "../components/BannerHome";
import Background from "../components/Background";
import Introduction from "../components/Introduction";
import MobileDevelopment from "../components/MobileDevelopment";
import Footer from "../components/Footer";

function Home() {
    return (
        <>
            <Header />
            <main>
                <BannerHome />
                <Background />
                <Introduction />
                <MobileDevelopment />
            </main>
            <Footer />
        </>
    )
}
export default Home;