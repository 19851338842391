import React from "react";
import styles from "./Introduction.module.scss";
import healthcare from "../assets/images/healthcare.png";
import software from "../assets/images/software.png";
import training from "../assets/images/training.png";

function Introduction() {
    return (
        <section className={styles.containerhome}>
            <div className={styles.cards}>
                <img src={healthcare} className={styles.icons} alt="Icon soins de santé" />
                <h3 className={styles.title_icons}>Healthcare</h3>
                <p className={styles.paragraph}>We develop predictive and diagnostic algorithms that adapt to your applications through supervised and unsupervised learning.</p>
            </div>
            <div className={styles.cards}>
                <img src={software} className={styles.icons} alt="Icon logiciel" />
                <h3 className={styles.title_icons}>Software</h3>
                <p className={styles.paragraph}>Our company develops all types of custom applications, with many years of professional experience in many fields. Several certifications in a wide range of technologies guarantee you a precise and faultless level of expertise.</p>
            </div>
            <div className={styles.cards}>
                <img src={training} className={styles.icons} alt="Icon entraînement" />
                <h3 className={styles.title_icons}>Training</h3>
                <p className={styles.paragraph}>FDevelopment company can train you in Windows development or full stack web development.</p>
            </div>
        </section>

    )
}

export default Introduction;