import React from "react";
import ia5 from "../assets/images/ia 5.jpg";
import styles from "./BannerProjects.module.scss";
import TypeWriter from "../components/TypeWriter";

function BannerProjects() {
    return (
        <section className={styles.bannerprojects}>
            <img src={ia5} alt="Robot IA médical 4" className={styles.img5} />
            <h1><TypeWriter text="Our Medical Projects find here the list of all our applications and projects related to medical and artificial intelligence" /></h1>
        </section>
    )
}

export default BannerProjects;