import React from "react";
import styles from "./ProjectsFdev.module.scss";
import argos from "../assets/images/argos.png";
import glycoscan from "../assets/images/glycoscan.png";
import vitalguard from "../assets/images/vitalguard.png";

function ProjectsFdev() {
    return (
        <section className={styles.projects}>
            <section className={styles.containerprojects}>
                <div className={styles.cards}>
                    <img src={argos} className={styles.icons} alt="Icon Argos Messenger" />
                    <h3 className={styles.title_projects}>ARGOS Messenger</h3>
                    <p className={styles.p_projects}>ARGOS Messenger is a PUSH notification server enabling you to create your own data exchanges between your applications via secure websockets.< br />Make your applications communicative and choose your own communication protocol.< br />ARGOS Messenger is totally configurable and open.< br />ARGOSMessenger is an extension of the ARGOS project that can operate independently.</p>
                    <p className={styles.p_request}>Brochure request to the following email address :</p>
                    <a href="mailto:contact@fdevelopment.eu" className="mail">contact@fdevelopment.eu</a>
                </div>
                <div className={styles.cards}>
                    <img src={glycoscan} className={styles.icons} alt="Icon Glyco Scan" />
                    <h3 className={styles.title_projects}>GLYCO Scan</h3>
                    <p className={styles.p_projects}>Fighting diabetes by reducing the use of insulin and improving living conditions for diabetics.< br />This project is currently under development and combines artificial intelligence with the help of healthcare professionnals such as dieticians.</p>
                    <p className={styles.p_request}>Brochure request to the following email address :</p>
                    <a href="mailto:contact@fdevelopment.eu" className="mail">contact@fdevelopment.eu</a>
                </div>
                <div className={styles.cards}>
                    <img src={vitalguard} className={styles.iconvitalguard} alt="Icon VitalGuard" />
                    <h3 className={styles.title_projects}>VitalGuard</h3>
                    <p className={styles.p_projects}>Is at the crossroads of technology and medicine, bringing revolutionary innovation to surgical safety.< br />Using artificial intelligence, we anticipate and prevent vital distress during surgery, trnasforming every operating room into a predictive environment.< br />Our algorithm detects signs of distress in advance, ensuring rapid intervention and improved patient safety.< br />With VitalGuard, you can offer unrivalled peace of mind to healthcare professionals and patients alike.</p>
                    <p className={styles.p_request}>Brochure request to the following email address :</p>
                    <a href="mailto:contact@fdevelopment.eu" className="mail">contact@fdevelopment.eu</a>
                </div>
            </section>
            <div className={styles.container}>
                <h4 className={styles.title_container}>We are developers, development is our voice</h4>
                <p className={styles.p_container}>Computers are an integral part of everyone's daily life, to help us live better, it is important that software is made with competence and logic, our goal is to improve the daily life of everyone.</p>
            </div>
        </section>
    )
}

export default ProjectsFdev;