import React from "react";
import styles from "./Background.module.scss";

function Background() {
    return (
        <section className="background">
            <div className={styles.cube}></div>
            <div className={styles.cube}></div>
            <div className={styles.cube}></div>
            <div className={styles.cube}></div>
            <div className={styles.cube}></div>
        </section>
    )
};

export default Background;